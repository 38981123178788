import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 任务的按供应商分布统计
 * @param {Object} data
 */
export function getCompanyStatistic (data) {
  return request({
    url: '/ooh-msp/v2/taskitemstatistic/getcompanystatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 任务的按站点分布统计
 * @param {Object} data
 */
export function getDomainStatistic (data) {
  return request({
    url: '/ooh-msp/v2/taskitemstatistic/getdomainstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 任务的按状态分布统计
 * @param {Object} data
 */
export function getStatusStatistic (data) {
  return request({
    url: '/ooh-msp/v2/taskitemstatistic/getstatusstatistic',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 任务进度统计
 * @param {Object} data
 */
export function taskProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskitemstatistic/taskprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 统计运维费用
 * @param {Object} data
 */
export function getPriceStatistical (data) {
  return request({
    url: '/ooh-msp/v2/taskitemstatistic/getpricestatistical',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 统计任务总费用
 * @param {Object} data
 */
export function getTaskMspTotalCost (data) {
  return request({
    url: '/ooh-msp/v2/taskitemstatistic/gettaskmsptotalcost',
    method: 'post',
    data: qs.stringify(data)
  })
}
