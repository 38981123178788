import { allFileApproval } from '@/api/order/orderfile'
import { getOrderProductStatistic, getOrderPublishProductStatistic, getOrderPublishResourcePage, getDemandDetailPage } from '@/api/msp/demand'
import { getOrder } from '@/api/order/order'
import { getTaskSortPage } from '@/api/msp/task'
import { getOrderScheduleDetailPage } from '@/api/msp/demandV3'
import { getTaskItemPage, getPublishingTaskitemPage } from '@/api/msp/taskitem'
import { taskProgress } from '@/api/msp/taskitemstatistic'

const getDefaultState = () => {
  return {
    demandId: null,
    demandIds: [], // 上刊需求id集合
    demandOrderId: null,
    materialManageTab: 'newScreen',
    approvalFileList: [],
    approvalFile: {},
    orderInfo: {},
    demandStartDate: '',
    demandEndDate: '',
    orderProductArray: [],
    selectedProductId: null,
    resourceListQuery: { // 获取画面设置资源查询条件
      orderId: null,
      startDate: '',
      endDate: '',
      pageNumber: 1,
      pageSize: 15
    },
    resourceData: {}, // 资源数据
    selectedResourceIds: [], // 选中需设置的资源id集合
    selectedTaskIds: [], // 梯牌选中资源任务id集合
    selectedTaskStatus: null, // 选中任务状态
    selectedStMediaAttr: {}, // 选中流媒体投放属性，包括规格和次数
    setMaxSide: 0, // 选中资源需设置的最大画面数
    setOperatingType: 1, // 设置类型，1：上刊设置，2：更改上刊设置
    demandStatus: 0, // 工单状态
    demandType: 1, // 工单类型，1：上刊设置，3：监播
    demandListQuery: {
      demandId: null,
      pageNumber: 1,
      pageSize: 15
    },
    /* new */
    pageQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    tableData: {},
    currentTaskId: null,
    currentResourceId: null,
    tempSelectedItem: [], // 临时存储分页选中项
    progressList: [], // 上刊进度统计
    indexSearchParams: null, // 首页搜索参数
    basicData: 2 // 上刊数据创建方式，1人工创建，2自动创建
  }
}
const state = getDefaultState()
const installSetting = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_demand_id (state, data) {
      state.demandId = data
    },
    set_demand_ids (state, data) {
      state.demandIds = data
    },
    set_demand_orderId (state, data) {
      state.demandOrderId = data
    },
    set_material_manage_tab (state, data) {
      state.materialManageTab = data
    },
    set_approval_file_list (state, data) {
      state.approvalFileList = data
    },
    set_approval_file (state, data) {
      state.approvalFile = data
    },
    set_order_info (state, data) {
      state.orderInfo = data
    },
    set_demand_startDate (state, data) {
      state.demandStartDate = data
    },
    set_demand_endDate (state, data) {
      state.demandEndDate = data
    },
    set_order_product_array (state, data) {
      state.orderProductArray = data
    },
    set_selected_productId (state, data) {
      state.selectedProductId = data
    },
    set_resource_list_query (state, data) {
      state.resourceListQuery = data
    },
    set_resource_data (state, data) {
      state.resourceData = data
    },
    set_selected_resourceIds (state, data) {
      state.selectedResourceIds = data
    },
    set_selected_taskIds (state, data) {
      state.selectedTaskIds = data
    },
    set_selected_task_status (state, data) {
      state.selectedTaskStatus = data
    },
    set_max_side (state, data) {
      state.setMaxSide = data
    },
    set_operating_type (state, data) {
      state.setOperatingType = data
    },
    set_demand_status (state, data) {
      state.demandStatus = data
    },
    set_demand_type (state, data) {
      state.demandType = data
    },
    set_demand_list_query (state, data) {
      state.demandListQuery = data
    },
    /** new */
    set_page_query (state, data) {
      state.pageQuery = Object.assign(getDefaultState().pageQuery, data)
    },
    set_table_data (state, data) {
      state.tableData = data
    },
    set_current_taskId (state, data) {
      state.currentTaskId = data
    },
    set_current_resourceId (state, data) {
      state.currentResourceId = data
    },
    set_progress_list (state, data) {
      state.progressList = data || []
    },
    set_temp_selected_item (state, data) {
      state.tempSelectedItem = (data instanceof Array) ? data : []
    },
    set_index_search_params (state, data) {
      state.indexSearchParams = data
    },
    set_selected_stmedia_attr (state, data) {
      state.selectedStMediaAttr = data
    },
    set_basic_data (state, data) {
      state.basicData = data
    }
  },
  actions: {
    /**
         * 获取文件审批历史记录
         * @param {Object} param0
         * @param {Object} data
         */
    getApprovalFileHistory ({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        allFileApproval({ orderId: data.orderId }).then(res => {
          commit('set_approval_file_list', res)
          // state.approvalFileList = res
          // if (res && !res.errcode && res.length > 0) {
          //   // state.approvalFile = res[0]
          //   commit('set_approval_file', res[0])
          // }
          resolve(res)
        })
      })
    },
    /**
     * 获取订单基本信息
     * @param {Object} param0
     */
    getOrderInfo ({ state, commit }) {
      getOrder({ orderId: state.demandOrderId }).then(res => {
        if (res && !res.errcode) {
          commit('set_order_info', res)
        } else {
          commit('set_order_info', res)
        }
      })
    },
    /**
     * 订单产品资源数量统计
     * @param {Object} param0
     */
    getOrderProductArray ({ state, commit }) {
      return new Promise((resolve) => {
        // const query = {
        //   orderId: state.demandOrderId,
        //   startDate: state.demandStartDate,
        //   endDate: state.demandEndDate
        // }
        getOrderProductStatistic({ taskId: state.currentTaskId }).then(res => {
          if (res && !res.errcode) {
            // state.orderProductArray = res
            commit('set_order_product_array', res)
            commit('set_selected_productId', res.length > 0 ? (state.selectedProductId ? state.selectedProductId : res[0].productId) : null)
          } else {
            // state.orderProductArray = []
            commit('set_order_product_array', [])
            commit('set_selected_productId', null)
          }
          resolve(res)
        })
      })
    },

    /**
     * 订单发布中资源 产品数量统计
     * @param {Object} param0
     */
    getOrderPublishProductArray ({ state, commit }) {
      return new Promise((resolve) => {
        const query = {
          orderId: state.demandOrderId
        }
        getOrderPublishProductStatistic(query).then(res => {
          if (res && !res.errcode) {
            // state.orderProductArray = res
            commit('set_order_product_array', res)
            commit('set_selected_productId', res.length > 0 ? (state.selectedProductId ? state.selectedProductId : res[0].productId) : null)
          } else {
            // state.orderProductArray = []
            commit('set_order_product_array', [])
            commit('set_selected_productId', null)
          }
          resolve(res)
        })
      })
    },

    /**
     * 订单发布中资源信息
     * @param {Object} param0
     * @param {Object} query 非必传，默认使用sotre中缓存数据
     */
    getMonitorResourceData ({ state, commit }, query) {
      state.resourceListQuery.orderId = state.demandOrderId
      // state.resourceListQuery.startDate = state.demandStartDate
      // state.resourceListQuery.endDate = state.demandEndDate
      const newQuery = Object.assign(state.resourceListQuery, query)
      commit('set_resource_list_query', newQuery)

      return new Promise((resolve) => {
        getOrderPublishResourcePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_resource_data', res)
          } else {
            commit('set_resource_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取上刊详情数据
     */
    getDemandDetailData ({ state, commit }, query) {
      state.demandListQuery.demandId = state.demandId
      const newQuery = Object.assign(state.demandListQuery, query)
      return new Promise((resolve) => {
        getDemandDetailPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_resource_data', res)
          } else {
            commit('set_resource_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 分页获取任务数据
     * @param {Object} param0
     * @param {Object} query // 查询条件参数
     */
    getTaskPageData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getTaskSortPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取任务详情数据
     * @param {Object} param0
     * @param {Object} query // 查询条件参数
     */
    getTaskDetailData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getOrderScheduleDetailPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', [])
          }
          resolve(res)
        })
      })
    },

    /**
     * 获取任务详情
     * @param {Object} param0
     * @param {Object} query // 查询条件参数
     */
    getTaskitemData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getTaskItemPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取当前发布中的资源
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getPublishingTaskitemData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getPublishingTaskitemPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取上刊进度统计数据
     * @param {*} param0
     * @param {*} params
     */
    getDemandProgressData ({ state, commit }, params) {
      const postData = Object.assign({ taskId: state.currentTaskId }, params)
      return new Promise((resolve) => {
        taskProgress(postData).then(res => {
          if (res && !res.errcode) {
            commit('set_progress_list', res)
          } else {
            commit('set_progress_list')
          }
          resolve(res)
        })
      })
    }
  }
}

export default installSetting
